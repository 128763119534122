import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import Footer from "../components/Footer";
import MenuBar from "../components/MenuBar";
import PageContent from "../components/PageContent";
import { StrapiBlogPost } from "../../graphql-types";
import "./../styles/index.scss";

import remarkGfm from 'remark-gfm'


const customParagraph = ({ node, ...props }) => {
  // Check if the direct children of this paragraph are an image
  const hasImage = node.children.some((element) => element.tagName === 'img');

  // Apply a custom style if there is an image. This custom style will allow the image to grow beyond the fixed width that contains the text content
  const style = hasImage ? "w-max" : ""

  return <p className={style} {...props} />;
};


interface BlogPostTemplateProps {
  pageContext: Pick<StrapiBlogPost, "title" | "body" | "date" | "author">
  & { newsBanner: ImageDataLike; stockImageSquare: any, stockImage: any };
}
const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({ pageContext }) => {
  const { title, body, date, stockImageSquare, author } = pageContext;
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  })

  return (
    <main className="w-screen bg-off-white">
      <title>{title}</title>

      <MenuBar />
      <PageContent>

        {/* Desktop */}
        <div className="hidden md:block">
          <div className="flex flex-row pt-28 m-auto justify-center mb-10">
            <div className="h-[400px] w-[400px]">
              <GatsbyImage loading="eager" image={getImage(stockImageSquare.localFile.childImageSharp)} alt="" imgStyle={{ borderRadius: "1.5rem" }}></GatsbyImage>
            </div>
            <div className="w-[300px] lg:w-[400px] ml-9">
              <div className="text-gray-dark"><span>{formattedDate} &bull; <span></span> {body.childMarkdownRemark.timeToRead} Minute Read</span></div>
              <div className="text-blue leading-none font-title text-2xl">
                  {title}
              </div>
                { author && <div className="text-sm"> By {author}</div> }
            </div>
          </div>
            <ReactMarkdown
              children={body.childMarkdownRemark.rawMarkdownBody}
              className="prose m-auto w-[736px] lg:w-[836px]"
              remarkPlugins={[remarkGfm]}
              components={{ img: ({ node, ...props }) => <img className="md:w-[700px] lg:w-[900px] xl:w-[1000px]" {...props} />, p: customParagraph}}
            />
        </div>

        {/* Mobile */}
        <div className="md:hidden pt-16 px-4 xs:px-8 sm:px-12">
          <div className="text-gray-dark"><span>{formattedDate} &bull; <span></span> {body.childMarkdownRemark.timeToRead} Minute Read</span></div>
          <div lang="en" className="text-blue text-xl font-title leading-none mt-5 break-words">
            {title}
          </div>
          { author && <div className="text-sm"> By {author}</div> }
          <div className="my-6 m-auto">
            <GatsbyImage loading="eager" image={getImage(stockImageSquare.localFile.childImageSharp)} alt="" imgStyle={{ borderRadius: "1.5rem" }}></GatsbyImage>
          </div>
          <ReactMarkdown
            children={body.childMarkdownRemark.rawMarkdownBody}
            className="prose"
            remarkPlugins={[remarkGfm]}
            components={{ img: ({ node, ...props }) => <img style={{ width: "100%" }} {...props} />}}
          />
        </div>
      </PageContent>
      <PageContent>
        <Footer />
      </PageContent>
    </main>
  );
};

export default BlogPostTemplate;
